import React from 'react';
import classNames from 'classnames';

// Receive iconClasses, iconStyle
export function buildIcon(iconClasses, iconStyle) {
    // Set the default if we don't have it
    iconClasses = (typeof iconClasses !== 'undefined') ?  iconClasses : null;
    iconStyle = (typeof iconStyle !== 'undefined') ?  iconStyle : {};
    // Create the icon if we have the right prop parameters
    var icon = null;
    if (iconClasses !== null) {
        var iconClassNames = classNames("fa", iconClasses);
        icon = <i className={iconClassNames} style={iconStyle}></i>;
    }
    return icon;
}