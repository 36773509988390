import React from 'react';
import createReactClass from 'create-react-class';

var DashboardTopic = createReactClass({
    render: function () {
        var subtitle = null;
        if (this.props.subtitle) {
            subtitle = this.props.subtitle;
        }
        return (
            <div className="topic topic-dashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 topic-outer">
                            <div className="topic-inner">
                                <h1>{this.props.title}</h1>
                                {subtitle}
                            </div>
                        </div>
                        <div className="col-sm-3 topic-outer text-right">
                            <div className="topic-inner">
                                <div className="topic-inner-link">
                                    <a href={this.props.linkUrl}>{this.props.linkLabel}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
})

export default DashboardTopic;