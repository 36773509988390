import last from "lodash/last";
import keys from "lodash/keys";
import clone from "lodash/clone";
import assign from "lodash/assign";

import numeral from 'numeral';

var FieldDataHelper = {
    PERCENTAGE_FIELDS: ["percent_total", "percent_budget", "one_year_growth"],
    getMetricName: function(metricId, fieldId, metrics) {
        var metricName = metrics[metricId];
        if (metricId == "percent_total" && fieldId != undefined) {
            switch(fieldId.slice(0, 4)) {
                case "rev_":
                    metricName = metricName.replace("total", "revenues");
                    break;
                case "exp_":
                    metricName = metricName.replace("total", "expenses");
                    break;
                case "ass_":
                    metricName = metricName.replace("total", "assets");
                    break;
                case "lia_":
                    metricName = metricName.replace("total", "liabilities");
                    break;
            }
        }
        return metricName;
    },
    getFormattedNumber(rawVal, method, format) {
        // Missing value
        if (rawVal == "" || rawVal === undefined) {
            return "";
        // Tax period
        } else if (typeof rawVal == "string" && rawVal.length == 7 && rawVal[4] == "-") {
            return rawVal;
        // Numeric values
        } else {
            var newVal = numeral().unformat(rawVal);
            if (this.PERCENTAGE_FIELDS.indexOf(method) > -1) {
                return numeral(newVal).format('0.0');
            } else if (format == "number_whole" || format === undefined) {
                return numeral(newVal).format('0,0');
            } else if (["value", "median", "mean"].indexOf(method) > -1) {
                return numeral(newVal).format('0,0');
            } else {
                return newVal;
            }
        }
    },
    // Port of get_field_name_detailed in util_core.py
    getFieldNameDetailed: function(fieldId, fieldName, metrics) {
        var fieldParts = fieldId.split(".");
        var detailedName = fieldName;
        if (fieldParts.length > 1) {
            var fieldIdPrimary = fieldParts[0];
            var fieldMetric = last(fieldParts);
            // Get the generic category of the field
            var metricName = this.getMetricName(fieldMetric, fieldIdPrimary, metrics);
            if (metricName != undefined && fieldMetric != "value") {
                detailedName = fieldName + ": " + metricName;
            }
        }
        return detailedName;
    },
    // Get all metric / method displays available for a given organization
    // metricIds, fieldId, metrics
    getAllMetricNameValues: function(config) {
        return config.metricIds.map(function(metricId){
            return {
                "display": this.getMetricName(metricId, config.fieldId, config.metrics),
                "value": metricId
            }
        }, this)
    },
    // Get all usable field names
    getAllFieldsGeneric: function(entityFields, exclusion) {
        var fieldIds = keys(entityFields).filter(function(fieldId) {
            return entityFields[fieldId] != undefined;
        });
        // Cycle through all of the fields, adding the relevant information
        var flattenedFields = [];
        fieldIds.forEach(function(fieldId) {
            var fieldData = entityFields[fieldId];
            var isGoodNonVariationField = FieldDataHelper.isGoodField(fieldId, entityFields, {
                numericFieldsOnly: false,
                seriesFieldsOnly: false,
                exclusion: exclusion
            });
            if (isGoodNonVariationField === true) {
                flattenedFields.push({
                    field_id: fieldData.field_id,
                    name_full: fieldData.name_full,
                    name_short: fieldData.name_short,
                    definition_short: fieldData.definition_short,
                    data_source: fieldData.data_source,
                    choices_source: fieldData.choices_source,
                    format: fieldData.format
                })
            } else if (fieldData.variations !== undefined) {
                keys(fieldData.variations).forEach(function(variationId) {
                    var isGoodVariationField = FieldDataHelper.isGoodField(fieldId, null, {
                        fieldData: fieldData.variations[variationId],
                        numericFieldsOnly: false,
                        seriesFieldsOnly: false,
                        exclusion: exclusion
                    });
                    if (isGoodVariationField === true) {
                        // Check for one last variation ID
                        if (fieldData.variations[variationId].variations !== undefined && fieldData.variations[variationId].variations.value == undefined) {
                            keys(fieldData.variations[variationId].variations).forEach(function(secondVariationId) {
                                var fullFieldId = fieldId + "." + variationId + "." + secondVariationId;
                                flattenedFields.push({
                                    field_id: fullFieldId,
                                    name_full: fieldData.variations[variationId].variations[secondVariationId].name_full || fieldData.variations[variationId].name_full,
                                    name_short: fieldData.variations[variationId].variations[secondVariationId].name_short || fieldData.variations[variationId].name_short,
                                    definition_short: fieldData.variations[variationId].variations[secondVariationId].definition_short || fieldData.variations[variationId].definition_short,
                                    data_source: fieldData.variations[variationId].variations[secondVariationId].data_source || fieldData.variations[variationId].data_source,
                                    choices_source: fieldData.variations[variationId].variations[secondVariationId].choices_source || fieldData.variations[variationId].choices_source,
                                    format: fieldData.variations[variationId].variations[secondVariationId].format || fieldData.variations[variationId].format
                                })
                            })
                        } else {
                            var fullFieldId = fieldId + "." + variationId;
                            flattenedFields.push({
                                field_id: fullFieldId,
                                name_full: fieldData.variations[variationId].name_full,
                                name_short: fieldData.variations[variationId].name_short,
                                definition_short: fieldData.variations[variationId].definition_short,
                                data_source: fieldData.variations[variationId].data_source,
                                choices_source: fieldData.variations[variationId].choices_source,
                                format: fieldData.variations[variationId].format
                            })
                        }
                    }
                })
            }
        });
        return flattenedFields
    },
    // Get all usable field names
    getAllFieldsNumeric: function(entityFields, exclusion) {
        var fieldIds = keys(entityFields).filter(function(fieldId) {
            return FieldDataHelper.isGoodField(fieldId, entityFields, {
                numericFieldsOnly: true,
                seriesFieldsOnly: false,
                exclusion: exclusion
            });
        });
        // Cycle through all of the fields, adding the relevant information
        var flattenedFields = [];
        fieldIds.forEach(function(fieldId) {
            var fieldData = entityFields[fieldId];
            flattenedFields.push({
                field_id: fieldData.field_id,
                name_full: fieldData.name_full,
                name_short: fieldData.name_short,
                definition_short: fieldData.definition_short
            })
        });
        return flattenedFields
    },
    // Get all usable field names
    getAllFieldsSeries: function(entityFields, exclusion) {
        var fieldIds = keys(entityFields).filter(function(fieldId) {
            return FieldDataHelper.isGoodField(fieldId, entityFields, {
                numericFieldsOnly: false,
                seriesFieldsOnly: true,
                exclusion: exclusion
            });
        });
        // Cycle through all of the fields, adding the relevant information
        var flattenedFields = [];
        fieldIds.forEach(function(fieldId) {
            var fieldData = entityFields[fieldId];
            flattenedFields.push({
                field_id: fieldData.field_id,
                name_full: fieldData.name_full,
                name_short: fieldData.name_short,
                definition_short: fieldData.definition_short
            })
        });
        return flattenedFields
    },
    //
    // DEPRECATED. Use isGoodField instead.
    // // Checker whether field is numeric or not
    // isNumericField: function(fieldId, entityFields) {
    //     // Get the field data
    //     var fieldData = entityFields[fieldId];;
    //     // Check whether the field is numeric, based on organizations.models.get_primary_numeric_fields
    //     if (fieldData !== undefined && fieldData.name_full !== undefined && fieldData.variations !== undefined && fieldData.variations.value !== undefined) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // },
    // // Checker whether field is a series numeric field or not
    // isSeriesField: function(fieldId, entityFields) {
    //     // Get the field data
    //     var fieldData = entityFields[fieldId];
    //     // Check whether the field is numeric, based on organizations.models.get_primary_numeric_fields
    //     if (fieldData !== undefined && fieldData.name_full !== undefined && fieldData.variations !== undefined && fieldData.variations.value !== undefined && fieldData.variations.one_year_growth !== undefined) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // },
    //
    // Whether should show the field or not. Options can contain:
    // numericFieldsOnly, seriesFieldsOnly, badFields (array of field IDs), exclusion, fieldData (optional)
    isGoodField: function(fieldId, entityFields, options) {
        // Set options to object if we don't have it
        options = options || {};
        // By default, we'll show a field
        var showField = true;
        // Get the field data, first from options, then from the entity fields
        var fieldData = options.fieldData;
        var fieldParts = fieldId.split(".");
        if (fieldData === undefined) {
            fieldData = entityFields[fieldId];
            // Go one level deeper, for nested fields (e.g., Schedule H Part V)
            if (fieldData === undefined && fieldParts.length > 1) {
                var fieldIdPrimary = fieldParts[0];
                var fieldIdSecondary = fieldParts.slice(1).join(".");
                fieldData = entityFields[fieldIdPrimary];
                if (fieldData.variations !== undefined) {
                    fieldData = fieldData.variations[fieldIdSecondary];
                }
            }
        }

        // If we don't have good field data, then it's not good
        if (fieldData === undefined || fieldData.name_full === undefined) {
            return false;
        }
        // Check numeric-only fields
        if (options.numericFieldsOnly === true) {
            showField = fieldData.variations !== undefined && fieldData.variations.value !== undefined;
        // Check series-only fields
        } else if (options.seriesFieldsOnly === true) {
            showField = fieldData.variations !== undefined && fieldData.variations.value !== undefined && fieldData.variations.one_year_growth !== undefined;
        }
        // Check for new exclusion
        if (showField === true && options.exclusion !== undefined && fieldData.exclusions !== undefined) {
            showField = fieldData.exclusions.indexOf(options.exclusion) === -1;
        }
        // Return the result
        return showField;
    },
    // Port of get_field_data in util_core.py
    // config = {fieldId: ..., entityFields: ..., includeVariationIds: ...}
    getFieldDataSpecific: function(config) {
        // Check for defaults
        if (config.includeVariationIds === undefined) {
            config.includeVariationIds = false;
        }
        // Check for force add method
        var addMethods = false;
        if (config.forceAddMethods === true) {
            addMethods = true;
        }
        // Initialize high-level variables
        var fieldDataNew = null;
        // Split the field ID into its parts
        var fieldParts = config.fieldId.split(".");
        var fieldIdPrimary = fieldParts[0];
        var fieldIdSecondary = fieldParts.slice(1).join(".");
        // Get the field data
        var fieldDataAll = config.entityFields[fieldIdPrimary];
        // Check if the fieldDataAll is undefined
        if (fieldDataAll === undefined) {
            console.log(`ERROR! FieldDataHelper error: ${fieldIdPrimary} field not within entityFields (${Object.keys(config.entityFields).join(', ')})!`)
            return null;
            // throw `FieldDataHelper error: ${fieldIdPrimary} field not within entityFields (${Object.keys(config.entityFields).join(', ')})!`;
        }
        // If it's just a simple field, return everything
        if (fieldDataAll.variations === undefined) {
            fieldDataNew = fieldDataAll;
            addMethods = true;
        // If we have variations and a secondary field, return all the information
        } else if (fieldDataAll.variations !== undefined && fieldIdSecondary) {
            fieldDataNew = clone(fieldDataAll, true);
            // See if it is a standard secondary field
            if (fieldDataNew.variations[fieldIdSecondary] !== undefined) {
                // Check for second layer of variations, indicating to add methods
                var hasSecondLayerVariations = fieldDataNew.variations[fieldIdSecondary].variations !== undefined;
                var hasSecondLayerMethods = fieldDataNew.variations[fieldIdSecondary].methods !== undefined;
                if (hasSecondLayerVariations === true && hasSecondLayerMethods === false) {
                    fieldDataNew.methods = keys(fieldDataNew.variations[fieldIdSecondary].variations);
                    // Added variationIds for strange edge cases where abnormal (double-nested) variations (e.g., fundraising event food and beverage)
                    fieldDataNew.variationIds = keys(fieldDataNew.variations[fieldIdSecondary].variations);
                }
                delete fieldDataNew.variations[fieldIdSecondary].variations;
                // Transfer properties from the variation within the secondary field to the outer object
                assign(fieldDataNew, fieldDataNew.variations[fieldIdSecondary]);
            // Check for more complicated secondary value search with different name but standard methods
            // ALSO HACK for length == 3: For nested objects with sub variations, such as fundraising event name,
            // 'simple' analyzer field. We're ignoring the analyzer field part because everything else is the same
            } else if (fieldIdSecondary.split(".").length >= 2) {
                var fieldIdSecondaryParts = fieldIdSecondary.split(".");
                fieldIdSecondary = fieldIdSecondaryParts[0];
                var method = fieldIdSecondaryParts[1];
                assign(fieldDataNew, fieldDataNew.variations[fieldIdSecondary]);
                // If we have a second layer of variations, add that second layer
                if (fieldDataNew.variations[method] !== undefined) {
                    assign(fieldDataNew, fieldDataNew.variations[method]);
                }
            }
            // Clean up the new field data
            delete fieldDataNew.variations;
        // If we have variations but not a secondary field, return high level information
        } else {
            fieldDataNew = clone(fieldDataAll, true);
            // If we want to include variation IDs, then do so.
            if (config.includeVariationIds && fieldDataNew.variations !== undefined) {
                fieldDataNew.variationIds = keys(fieldDataNew.variations);
            }
            // Delete the variation data, since we don't need it
            delete fieldDataNew.variations;
            addMethods = true;
        }
        // Make sure the field_id is accurate by loading the original field ID on the result
        fieldDataNew.field_id = config.fieldId;
        // Add the methods
        if (addMethods === true && fieldDataNew.methods === undefined) {
            fieldDataNew.methods = keys(fieldDataAll.variations);
        }
        // Add the icon
        var iconName = this.getFieldIcon(fieldDataNew);
        fieldDataNew.iconName = iconName;
        // Return the field data
        return fieldDataNew;
    },
    getFieldIcon(fieldData) {
        var iconName = "info-circle";
        if (fieldData.icon !== undefined) {
            iconName = fieldData.icon;
        } else if (fieldData.format === "timestamp") {
            iconName = "calendar";
        } else if (fieldData.prefix === "$") {
            iconName = "money";
        } else if (fieldData.field_id.indexOf("lia_") == 0) {
            iconName = "credit-card";
        }
        return iconName;
    },
    getDefaultEditingFilterValuesForField(fieldData) {
        var editingFilterValues = {};
        // Set the default methods / metrics for the fields
        if (fieldData.methods.indexOf("value") > -1) {
            editingFilterValues.method = "value";
        } else if (fieldData.methods.indexOf("include") > -1) {
            editingFilterValues.method = "include";
        } else if (fieldData.variation_default != undefined) {
            editingFilterValues.method = fieldData.variation_default;
        }
        // Set the default values, so we avoid React warnings
        if (fieldData.widget == "range") {
            editingFilterValues.gte = "";
            editingFilterValues.lte = "";
        }
        // Return
        return editingFilterValues;
    },
    getDefaultFilterData(fieldData) {
        var editingFilterValues = this.getDefaultEditingFilterValuesForField(fieldData);
        if (["choice", "vendors", "tax_preparer", "fundraisers", "form5500_vendors"].indexOf(fieldData.widget) > -1) {
            editingFilterValues.value = [];
        } else if (editingFilterValues.gte !== undefined) {
            console.log("getDefaultFilterData unknown filter type")
            console.log("fieldData")
            console.log(fieldData)
        }
        return editingFilterValues;
    },
    // Get field data, for when adding filters and don't have a method, so need
    // to default to a given variation
    getFieldDataSpecificWithWidgetCheck: function(config) {
        const FIELDS_TO_NOT_DO_FOUNDATION_REPLACEMENT = ['det_grant_geo_focus', 'det_grants_made_count']

        var fieldData = this.getFieldDataSpecific(config);
        // If we haven't loaded this field yet, then skip
        if (fieldData === null) {
            return null;
        }
        // Load the default metric
        if (fieldData.widget === undefined && config.method !== undefined) {
            var newFieldId = config.fieldId + "." + config.method;
            fieldData = this.getFieldDataSpecific(assign(config, {fieldId: newFieldId}));
        // Load the "value" metric, if we don't have a pre-selected metric
        } else if (fieldData.widget === undefined && fieldData.variationIds.indexOf("value") > -1) {
            var newFieldId = config.fieldId + ".value";
            fieldData = this.getFieldDataSpecific(assign(config, {fieldId: newFieldId}));
        // Load the default variation, if we have it
        } else if (fieldData.widget === undefined && fieldData.variation_default != undefined) {
            var newFieldId = config.fieldId + "." + fieldData.variation_default;
            fieldData = this.getFieldDataSpecific(assign(config, {fieldId: newFieldId}));
        }
        // Do replacements for foundation stuff
        if (config.replaceOrgWithFoundation === true && FIELDS_TO_NOT_DO_FOUNDATION_REPLACEMENT.indexOf(fieldData.field_id) == -1 && fieldData.definition_short) {
            // Make a copy so this doesn't affect other uses of this field
            fieldData = Object.assign({}, fieldData);
            // Keyword replacement
            fieldData.definition_short = fieldData.definition_short.replace("an organization", "a foundation").replace("organization", "foundation").replace("Organization", "Foundation")
        }
        return fieldData;
    }
};

export default FieldDataHelper;
