import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';

import { buildIcon } from './icon-creator.js';


var DropdownListHeaderItem = createReactClass({
    render: function () {
        var icon = buildIcon(this.props.iconClasses);
        var liClasses = classNames("dropdown-header", {"headline": this.props.asHeadline==true});
        return (
            <li role="presentation" className={liClasses}><span>{icon}{this.props.label}</span></li>
        )
    }
})

var DropdownListFooterItem = createReactClass({
    render: function () {
        var icon = buildIcon(this.props.iconClasses);
        if (this.props.url) {
            return (
                <li role="presentation" className="dropdown-footer-link"><a href={this.props.url}>{icon}{this.props.label}</a></li>
            )
        } else {
            return (
                <li role="presentation" className="dropdown-footer"><span>{icon}{this.props.label}</span></li>
            )
        }
    }
})

// Mixin for generating dropdowns
var DropdownListItem = createReactClass({
    propTypes: {
        // Required properties
        dropdownItems: PropTypes.array,
        dropdownRef: PropTypes.func,
        // Optional properties
        onShowDropdown: PropTypes.func,
        emptyLabel: PropTypes.string,
        headerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        footerLabel: PropTypes.string,
        footerUrl: PropTypes.string,
        condensed: PropTypes.bool,
        wordwrap: PropTypes.bool,
        alignRight: PropTypes.bool,
        keepMenuOpen: PropTypes.bool
    },
    render: function () {
        // Initialize variables
        var loadingItem = null;
        var headerLabelElement = null;
        var footerElement = null;
        var emptyElement = null;

        // Check if data not present, display loading, or if empty data, show empty label
        if (this.props.dropdownItems == null) {
            loadingItem = (<DropdownListHeaderItem iconClasses={["fa-cog", "fa-spin", "fa-fw"]} label="Loading..." />);
        } else if (this.props.dropdownItems.length == 0 && typeof this.props.emptyLabel != 'undefined') {
            emptyElement = (<li role="presentation" className="dropdown-text">{this.props.emptyLabel}</li>);
        }

        // Create header item, if it exists
        if (this.props.headerLabel) {
            headerLabelElement = (<DropdownListHeaderItem label={this.props.headerLabel} asHeadline={true} />);
        }

        // Create footer item, if it exists
        if (this.props.footerLabel) {
            footerElement = (<DropdownListFooterItem label={this.props.footerLabel} url={this.props.footerUrl} />);
        }
        var ulClasses = classNames("dropdown-menu", "text-left", {
            "dropdown-menu-condensed": this.props.condensed == true, 
            "dropdown-menu-wrap": this.props.wordwrap == true,
            "dropdown-menu-right": this.props.alignRight == true
        });

        // Create the icon for the label
        var labelIcon = buildIcon(this.props.labelIconClasses, this.props.labelIconStyle);

        // Create the dropdown thingy
        return (
            <li>
                <Dropdown ref={this.props.dropdownRef} onShow={this.props.onShowDropdown}>
                    <DropdownTrigger style={{cursor: "pointer"}} title={this.props.xsLabel}>
                        {labelIcon}{this.props.label}<span className="visible-xs-inline" style={{paddingLeft: "0.5em"}}>{this.props.xsLabel}</span>
                    </DropdownTrigger>
                    <DropdownContent className="dropdown-right-align">
                        <ul className={ulClasses} style={this.props.dropdownStyles}>
                            {headerLabelElement}
                            {this.props.dropdownItems}
                            {loadingItem}
                            {emptyElement}
                            {footerElement}
                        </ul>
                    </DropdownContent>
                </Dropdown>
            </li>
        );
    }
});

export default DropdownListItem;