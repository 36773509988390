import React from 'react';
import classNames from 'classnames';
// import createReactClass from 'create-react-class';

function RadioOption(props){
    // Default values
    var inputAttributes = {
        type: "radio",
        name: props.fieldName,
        id: props.fieldName + "_id",
        value: props.value,
        onChange: () => {props.disabled != true && props.handleChange(props.fieldName, props.value)}
    };
    // Checked if boolean True or the selected option equals the value of the radio
    if (typeof props.selectedOption == "boolean") {
        if (props.selectedOption === true) {
            inputAttributes.checked = true;
        } else {
            inputAttributes.checked = false;
        }
    } else {
        if (props.selectedOption == props.value) {
            inputAttributes.checked = true;
        } else {
            inputAttributes.checked = false;
        }
    }
    // Set to disabled, if so
    if (props.isDisabled == true) {
        inputAttributes.disabled = "disabled";
    }
    // Create the radio classes
    var divClasses = classNames({"radio": props.inline != true, "disabled": props.isDisabled == true});
    var labelClasses = classNames({"radio-inline": props.inline == true, "text-muted": props.isDisabled == true});
    // Create the radio option, which depends on inline or not
    var radioInner = (
        <label className={labelClasses}>
            <input {...inputAttributes} />
            <span>{props.label}</span>
        </label>
    )
    if (props.inline == true) {
        return radioInner;
    } else {
        return <div className={divClasses} style={props.styles}>{radioInner}</div>;
    }
}

// var RadioOption = createReactClass({
//     handleChange: function() {
//         if (this.props.disabled != true) {
//             this.props.handleChange(this.props.fieldName, this.props.value);
//         }
//     },
//     render: function() {
//         // Default values
//         var inputAttributes = {
//             type: "radio",
//             name: this.props.fieldName,
//             id: this.props.fieldName + "_id",
//             value: this.props.value,
//             onChange: this.handleChange
//         };
//         // Checked if boolean True or the selected option equals the value of the radio
//         if (typeof this.props.selectedOption == "boolean") {
//             if (this.props.selectedOption === true) {
//                 inputAttributes["checked"] = true;
//             } else {
//                 inputAttributes["checked"] = false;
//             }
//         } else {
//             if (this.props.selectedOption == this.props.value) {
//                 inputAttributes["checked"] = true;
//             } else {
//                 inputAttributes["checked"] = false;
//             }
//         }
//         // Set to disabled, if so
//         if (this.props.isDisabled == true) {
//             inputAttributes["disabled"] = "disabled";
//         }
//         // Create the radio classes
//         var divClasses = classNames({"radio": this.props.inline != true, "disabled": this.props.isDisabled == true});
//         var labelClasses = classNames({"radio-inline": this.props.inline == true, "text-muted": this.props.isDisabled == true});
//         // Create the radio option, which depends on inline or not
//         var radioInner = React.createElement("label", {className: labelClasses}, 
//             React.createElement("input", inputAttributes),
//             React.createElement("span", null, this.props.label)
//         );
//         if (this.props.inline == true) {
//             return radioInner
//         } else {
//             var radioOuterProps = {className: divClasses};
//             if (this.props.styles != undefined) {
//                 radioOuterProps.style = this.props.styles;
//             }
//             return (
//                 React.createElement("div", radioOuterProps, 
//                     radioInner
//                 )
//             )
//         }
//     }
// })

export default RadioOption;
