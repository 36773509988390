import React from 'react';


function LoadingSpinner(props) {
    if (props.show) {
        return (
            <div className="text-center text-muted">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
            </div>
        )
    } else {
        return null;
    }
}

export default LoadingSpinner;